import { ReactNode, useEffect, useRef, useState } from 'react'
import * as React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import styled, { css } from 'styled-components'
import Portal from 'app.components/Portal'
import { fadeIn, fadeOut, leftIn, leftOut } from 'app.styled/keyframes'

type TAnimation = 'left' | 'fade' | 'bottom'

export interface IFModalProps {
  children?: ReactNode
  open?: boolean
  onClose?: () => void
  className?: string
  animation?: TAnimation
  disableBackDropClose?: boolean
  isModalOverlapping?: boolean
}

const Modal: React.FC<IFModalProps> = ({
  children,
  open = false,
  onClose = () => {},
  animation = 'fade',
  disableBackDropClose = false,
  isModalOverlapping = false,
  className,
}) => {
  const [openAnimationEnded, setOpenAnimationEnded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!open) return
    const activeElement = document.activeElement
    if (!(activeElement instanceof HTMLElement)) return
    activeElement.blur()
    ref.current?.focus()
    if (!isModalOverlapping) {
      document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
        `
    }
    return () => {
      if (isModalOverlapping) return
      const scrollY = document.body.style.top
      document.body.style.cssText = ''
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
    }
  }, [open])

  useEffect(() => {
    if (open) {
      setOpenAnimationEnded(false)
    }
  }, [open])

  const handleAnimationEnd = () => {
    if (openAnimationEnded) {
      onClose()
    }
  }

  const handleBackDropClick = () => {
    if (disableBackDropClose) return
    setOpenAnimationEnded(true)
  }

  if (!open) return null
  return (
    <Portal>
      <StyledWrapper
        onMouseOver={() => ref.current?.focus()}
        ref={ref}
        className={className}
        animation={animation}
        openAnimationEnded={openAnimationEnded}
        onAnimationEnd={handleAnimationEnd}
      >
        <div className="dimmed" onClick={handleBackDropClick} />
        <div className="content">{children}</div>
      </StyledWrapper>
    </Portal>
  )
}

export default Modal

const createRootNode = (id: string) => {
  let newElement = document.getElementById(id)
  if (!newElement) {
    newElement = document.createElement('div')
    newElement.id = id
    document.body.appendChild(newElement)
  }

  return newElement
}

export const createOpenModal = <Props extends IFModalProps>(
  ModalComponent: React.FC<Props>,
) => {
  return (renderPayload: Props) => {
    const elementId = `portal_${Math.random().toString(36).substring(2, 11)}`

    const root = createRootNode(elementId)

    render(
      <ModalComponent
        {...renderPayload}
        onClose={() => {
          renderPayload?.onClose && renderPayload.onClose()
          unmountComponentAtNode(root)
          document.body.removeChild(root)
        }}
      />,
      root,
    )
  }
}

const StyledWrapper = styled.div<{
  animation: TAnimation
  openAnimationEnded: boolean
}>`
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 30;
  position: fixed;
  ${({ openAnimationEnded, animation }) => {
    if (animation === 'fade')
      return css`
        animation: ${openAnimationEnded ? fadeOut : fadeIn} 0.15s;
      `
    if (animation === 'left')
      return css`
        animation: ${openAnimationEnded ? leftOut : leftIn} 0.15s;
      `
  }}
  .dimmed {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.7);
  }
`
