import { useEffect, FC } from 'react'
import styled from 'styled-components'
import { ActivityFile } from 'generated/graphql'

const KakaoButton = styled.div`
  width: 100%;
  height: 100%;
`

export interface KakaoShareButtonProps {
  objectType?: string
  title?: string
  text?: string
  url?: string
  thumbnailImage?: ActivityFile | null
  className?: string
  ogImage?: string
  onClick: () => void
}

export const KakaoShareButton: FC<KakaoShareButtonProps> = ({
  objectType = 'feed',
  title = '',
  text = '',
  url,
  thumbnailImage,
  className,
  children,
  ogImage,
  onClick,
}) => {
  const handleClick = () => {
    const kakao = (window as any).Kakao

    if (!kakao) {
      return
    }

    const imageUrl = thumbnailImage
      ? thumbnailImage.url
      : ogImage
      ? ogImage
      : 'https://linkareer.com/static/images/linkareer.png'

    switch (objectType) {
      case 'text': {
        kakao.Link.sendDefault({
          objectType: 'text',
          text,
          link: {
            webUrl: url ?? window.location.href,
            mobileWebUrl: url ?? window.location.href,
          },
        })
        break
      }
      default: {
        kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title: `[링커리어] ${title}`,
            imageUrl,
            imageWidth: 800,
            imageHeight: 400,
            link: {
              webUrl: url ?? window.location.href,
              mobileWebUrl: url ?? window.location.href,
            },
          },
        })
        break
      }
    }
    onClick()
  }

  useEffect(() => {
    if (!(window as any)?.Kakao?.isInitialized()) {
      ;(window as any)?.Kakao?.init('171d15c1a52fc8d0eb38550f93c1cbee')
    }
  }, [])

  return (
    <KakaoButton onClick={handleClick} className={className}>
      {children}
    </KakaoButton>
  )
}
