
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Reportable": [
      "NewsItem",
      "NewsComment"
    ],
    "Actor": [
      "Channel",
      "User"
    ],
    "SimpleNotification": [
      "AcceptedMentorCommentRewardNotification",
      "ActivityApprovedNotification",
      "ActivityRefusedNotification",
      "BookmarkToNewsItemNotification",
      "ChatMessageReplyNotification",
      "CommentToCommentNotification",
      "CommentToMentorPostNotification",
      "CommentToPostNotification",
      "FollowToChannelNotification",
      "LikeToNewsItemNotification",
      "MatchedMentorCommentRewardNotification",
      "MentorApprovedNotification",
      "NewActivityNotification",
      "NewNewsItemNotification",
      "NewsCommentToNewsCommentNotification",
      "NewsCommentToNewsItemNotification",
      "OrganizationApprovedNotification",
      "OrganizationPendingNotification",
      "RecommendedMentorPostNotification",
      "ReplyToActivityNotification",
      "ReplyToReplyNotification",
      "ScrapActivityCloseSoonerNotification",
      "ScrapActivityCloseSoonNotification"
    ],
    "ActivityFilterItem": [
      "ActivityFilterItemByID",
      "ActivityFitlerItemByRange"
    ],
    "ActivityFilterItemBase": [
      "ActivityFilterItemByID",
      "ActivityFitlerItemByRange"
    ],
    "CommunityBookmarkable": [
      "Community_Menu"
    ],
    "CareerLanguageItem": [
      "CareerLanguageConversation",
      "CareerLanguageTest"
    ],
    "UserScrapResult": [
      "NewsItem",
      "UserScrap",
      "UserScrapCommunity"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Reportable": [
      "NewsItem",
      "NewsComment"
    ],
    "Actor": [
      "Channel",
      "User"
    ],
    "SimpleNotification": [
      "AcceptedMentorCommentRewardNotification",
      "ActivityApprovedNotification",
      "ActivityRefusedNotification",
      "BookmarkToNewsItemNotification",
      "ChatMessageReplyNotification",
      "CommentToCommentNotification",
      "CommentToMentorPostNotification",
      "CommentToPostNotification",
      "FollowToChannelNotification",
      "LikeToNewsItemNotification",
      "MatchedMentorCommentRewardNotification",
      "MentorApprovedNotification",
      "NewActivityNotification",
      "NewNewsItemNotification",
      "NewsCommentToNewsCommentNotification",
      "NewsCommentToNewsItemNotification",
      "OrganizationApprovedNotification",
      "OrganizationPendingNotification",
      "RecommendedMentorPostNotification",
      "ReplyToActivityNotification",
      "ReplyToReplyNotification",
      "ScrapActivityCloseSoonerNotification",
      "ScrapActivityCloseSoonNotification"
    ],
    "ActivityFilterItem": [
      "ActivityFilterItemByID",
      "ActivityFitlerItemByRange"
    ],
    "ActivityFilterItemBase": [
      "ActivityFilterItemByID",
      "ActivityFitlerItemByRange"
    ],
    "CommunityBookmarkable": [
      "Community_Menu"
    ],
    "CareerLanguageItem": [
      "CareerLanguageConversation",
      "CareerLanguageTest"
    ],
    "UserScrapResult": [
      "NewsItem",
      "UserScrap",
      "UserScrapCommunity"
    ]
  }
};
      export default result;
    