import {
  createContext,
  FC,
  useContext,
  useState,
  useRef,
  ComponentProps,
} from 'react'
import { useUA } from 'context'
import gql from 'graphql-tag'
import unionBy from 'lodash/unionBy'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useGqlChattingContextCurrentUserQuery } from 'generated/graphql'
import ShareModal from '@app.components/modals/ShareModal'
import { LINKAREER_CHAT_URL } from 'utils/config'
import ChattingIframeSkeleton from '../component/ChattingIframeSkeleton'
import useChattingLazyLoad from '../hook/useChattingLazyLoad'
import useChattingRequestMessageHandler from '../hook/useChattingRequestMessageHandler'
import useParseChatQueryEffect from '../hook/useParseChatQueryEffect'
import { TChattingQueryParamKeys } from '../type/chattingQueryParams'
import { TChattingReportTypes } from '../type/chattingReport.type'
import chattingQueryParamsGenerator, {
  TQueryParamsGeneratorOption,
} from './chattingQueryParamsGenerator'

gql`
  query gqlChattingContextCurrentUser {
    currentUser {
      id
      tokenSet {
        accessToken
      }
    }
  }
`

type TChattingQueryParamOption =
  TQueryParamsGeneratorOption<TChattingQueryParamKeys>

interface IFChattingContextProps {
  isOpen: boolean
  isLoaded: boolean
  openChatting: (reportOption?: TChattingReportTypes) => void
  closeChatting: () => void
  reportToChatting: (reportOption: TChattingReportTypes) => void
  joinChatRoom: (chatRoomId: string) => void
}

const updateQueryParams = (
  prevOptions: TChattingQueryParamOption[],
  updateOptions: TChattingQueryParamOption[],
): TChattingQueryParamOption[] => {
  return unionBy(updateOptions, prevOptions, 'paramKey')
}

const ChattingContext = createContext<IFChattingContextProps | undefined>(
  undefined,
)

export const useChattingContext = () => {
  const context = useContext(ChattingContext)
  if (!context) {
    throw new Error('ChattingContext must be used within a ChattingProvider')
  }

  return context
}

type TShareModalProps = Pick<
  ComponentProps<typeof ShareModal>,
  'open' | 'quote' | 'shareLink' | 'ogImage' | 'title'
>

export const ChattingProvider: FC = ({ children }) => {
  const router = useRouter()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { isMobile } = useUA()
  const [isShouldLoad, setIsShouldLoad] = useState<boolean>(false)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [queryParam, setQueryParam] = useState<TChattingQueryParamOption[]>([])

  const [shareModalProps, setShareModalProps] = useState<TShareModalProps>({
    open: false,
    quote: '링커리어 채팅방 공유',
    title: '채팅방 입장하기',
    shareLink: 'https://www.linkareer.com/',
    ogImage: 'https://api.linkareer.com/attachments/548',
  })

  useGqlChattingContextCurrentUserQuery({
    onCompleted: (data) => {
      const accessToken = data?.currentUser?.tokenSet?.accessToken
      if (!accessToken) {
        return
      }

      setQueryParam((prev) =>
        updateQueryParams(prev, [
          {
            paramKey: 'accessToken',
            paramValue: accessToken,
          },
        ]),
      )
      checkSureChattingLoad()
    },
  })

  const iframeUrl = `${LINKAREER_CHAT_URL}?${chattingQueryParamsGenerator(
    queryParam,
  )}`

  const checkSureChattingLoad = () => {
    if (!isLoaded && !isShouldLoad) {
      setIsShouldLoad(true)
    }
  }

  const openChatting = (reportOption?: TChattingReportTypes) => {
    checkSureChattingLoad()
    if (reportOption) {
      handleReportToChatting(reportOption)
    }
    setIsOpen(true)
  }

  const closeChatting = () => {
    setIsOpen(false)
  }

  const handleReportToChatting = (reportOption: TChattingReportTypes) => {
    if (typeof window === 'undefined') {
      return
    }
    iframeRef.current?.contentWindow?.postMessage(reportOption, {
      targetOrigin: LINKAREER_CHAT_URL,
    })
  }

  // Todo
  // Mobile Web 채팅 연동되면 isMobile 제거
  const handleJoinChatRoom = (chatRoomId: string) => {
    const reportType = isOpen
      ? 'reportChatJoinChatRoom'
      : 'reportChatUpdateMyChatRoom'

    if (isOpen && !isMobile) {
      return openChatting({
        type: reportType,
        data: { chatRoomId },
      })
    }

    handleReportToChatting({
      type: reportType,
      data: { chatRoomId },
    })
  }

  useParseChatQueryEffect(router, isLoaded, openChatting)
  useChattingLazyLoad(() => setIsShouldLoad(true))
  useChattingRequestMessageHandler({
    onRequestClose: () => {
      closeChatting()
    },
    onRequestSnsShare: (url) => {
      setShareModalProps((prev) => ({
        ...prev,
        open: true,
        shareLink: url,
      }))
    },
  })

  return (
    <ChattingContext.Provider
      value={{
        isOpen,
        isLoaded,
        openChatting,
        closeChatting,
        reportToChatting: handleReportToChatting,
        joinChatRoom: handleJoinChatRoom,
      }}
    >
      {children}
      <StyledChattingIframeWrapper
        data-is-open={isOpen}
        data-is-loaded={isLoaded}
      >
        <section role="chatting-web">
          <ChattingIframeSkeleton className="iframe-skeleton" />
          {isShouldLoad && (
            <iframe
              ref={iframeRef}
              src={iframeUrl}
              onLoad={() => setIsLoaded(true)}
              loading="lazy"
              allow="clipboard-read; clipboard-write"
            />
          )}
        </section>
      </StyledChattingIframeWrapper>

      {/* 공유 모달 */}
      <StyledShareModal
        {...shareModalProps}
        onClose={() =>
          setShareModalProps((prev) => ({
            ...prev,
            open: false,
          }))
        }
        className="share-modal"
      />
    </ChattingContext.Provider>
  )
}

const StyledChattingIframeWrapper = styled.div`
  position: fixed;
  transition: right 0.3s ease-in-out;
  top: 0;
  right: -370px;
  z-index: 9999;

  > section {
    position: relative;
    background-color: white;
    border-left: 1px solid ${(props) => props.theme.line.line_3};

    > iframe {
      width: 360px;
      height: 100vh;
    }

    > .iframe-skeleton {
      position: absolute;
      top: 0;
      left: 0;
      width: 360px;
      height: 100vh;
    }
  }

  &[data-is-open='true'] {
    right: 0;
  }

  &[data-is-loaded='true'] {
    .iframe-skeleton {
      display: none;
    }
  }
`

const StyledShareModal = styled(ShareModal)`
  z-index: 10000 !important;
`
